<template>
  <div>
    <Header></Header>
    <!-- <div class="header_fix">
      <div class="header flex-box">
        <router-link to="/" class="icon_logo">
          <img src="../assets/static/icon/icon_logo.png" class="icon_logo" alt="">
        </router-link>
      </div>
    </div> -->
    <div class="content">
      <div class="login">
        <div class="login_box">
          <div class="fs22 pb20">欢迎登录</div>
          <div class="flex-box flex-center pb30 login_type">
            <div @click="changeType(0)" class="fs16" :class="typeIndex == 0?'active':''">密码登录</div>
            <div @click="changeType(1)" class="fs16" :class="typeIndex == 1 ? 'active' : ''">验证码登录</div>
          </div>
          <template v-if="typeIndex == 0">
            <div class="input_box flex-box fs18 account">
              <input type="text" v-model="user.tel" class="flex-grow-1" placeholder="请输入手机号">
            </div>
            <div class="input_box flex-box fs18">
              <input type="password" v-model="user.password" class="flex-grow-1" placeholder="请输入密码">
            </div>
            <div class="register fs18" @click="login">登录</div>
          </template>
          <template v-if="typeIndex == 1">
            <div class="input_box flex-box fs18 account">
              <input type="text" v-model="user.tel" class="flex-grow-1" placeholder="请输入手机号" />
              <div class="code normal" v-if="show" @click="sendSms">发送验证码</div>
              <div v-else class="code normal count">{{ count }}</div>
            </div>
            <div class="input_box flex-box fs18">
              <input type="text" v-model="user.smsCode" class="flex-grow-1" placeholder="请输入验证码" />
            </div>
            <div class="register fs18" @click="login">登录</div>
          </template>
          <div class="flex-box flex-between fs18 pt40">
            <a href="javascript:;" class="col9" @click="jump(1)">忘记密码？</a>
            <a href="javascript:;" class="normal to_register flex-box" @click="jump(2)">立即注册</a>
          </div>
        </div>
      </div>
    </div>
    <div class="m1440 tr ptb30 fs18">环保桥（上海）环境技术有限公司</div>
  </div>
</template>
<script>
import { setTimeout } from 'timers';
import cookie from "vue-cookies";
import axios from "axios";
export default {
  data() {
    return {
      user: {
        tel: '',
        password: '',
        smsCode: ''
      },
      typeIndex: 0,
      show: true,
      count: 60,
    }
  },
  methods: {
    changeType(index) {
      this.typeIndex = index;
    },
    jump(type) {
      if (type == 1) {
        this.$router.push("/password");
      } else {
        this.$router.push("/register");
      }
    },

    login() {
      let that = this;
      let user = that.user;
      let post_data = {
        tel: user.tel
      }
      if (post_data.tel != '') {
        if (!(/^1[3456789]\d{9}$/.test(post_data.tel))) {
          that.$message.error("请填写正确的手机号码！");
          return;
        }
      } else {
        that.$message.error("请输入手机号！");
        return;
      }
      let typeIndex = that.typeIndex
      if (typeIndex == 0) {
        post_data.password = user.password
        if (!post_data.password) {
          that.$message.error("请输入密码！");
          return;
        }
        post_data.loginType = '0'
      } else {
        post_data.smsCode = user.smsCode
        if (!post_data.smsCode) {
          that.$message.error("请输入验证码！");
          return;
        }
        post_data.loginType = '1'
      }
      that.$api.apiPostLogin(post_data).then((res) => {
        if (res.resCode == 0) {
          that.$message.success('登录成功');
          cookie.set("huanbaoqiao_userinfo", res.root.user)
          cookie.set("huanbaoqiao_token", res.root.token);
          axios.defaults.headers["token"] = res.root.token; // 登录标识
          setTimeout(function () {
            that.$router.push("/");
            // that.$router.back()
          }, 800)
        }else if (res.resCode == 1022) {
          that.$message.error('密码错误，请输入正确密码或重置密码');
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

    sendSms: function () {
      let that = this;
      let data = that.user;
      if (data.tel != '') {
        if (!(/^1[3456789]\d{9}$/.test(data.tel))) {
          that.$message.error("请填写正确的手机号码！");
          return;
        }
      } else {
        that.$message.error("请输入手机号！");
        return;
      }

      that.$api.checkUserStatus({tel: data.tel}).then((res) => {
        if (res.resCode == 0) {
          that.$api.apiPostSend({ tel: data.tel })
        .then((res) => {
          // console.log(data_res);
          if (res.resCode == 0) {
            const TIME_COUNT = 60;
            if (!that.timer) {
              that.count = TIME_COUNT;
              that.show = false;
              that.timer = setInterval(() => {
                if (that.count > 0 && that.count <= TIME_COUNT) {
                  that.count--;
                } else {
                  that.show = true;

                  clearInterval(that.timer);

                  that.timer = null;
                }
              }, 1000);
            }
            // that.user.code = res.root
          } else {
            that.$message.error(res.resMsg);
          }
        });

        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

  }
}

</script>

